import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import axios from "axios";

// Connects to data-controller="post-collections"
export default class extends Controller {
  connect() {
    this.initTomSelect();
  }

  disconnect() {
    if (this.collectionsSelect) {
      this.collectionsSelect.destroy();
    }
  }

  initTomSelect() {
    const addCollectionPath = "/post_collections";
    const removeCollectionPath = "/post_collections/delete_collections";

    // Query the select element with ID "post_collection_ids"
    const collectionsList = document.querySelector("#post_collection_ids");
    if (collectionsList && !collectionsList.tomSelect) {
      // Ensure Tom Select isn't initialized already
      const config = {
        plugins: {
          remove_button: {
            title: "Remove this item",
          },
        },
        persist: true,
        onItemAdd: (value) => this.onCollectionChange(value, addCollectionPath),
        onItemRemove: (value) =>
          this.onCollectionChange(value, removeCollectionPath),
        create: (input, callback) => this.onCollectionCreate(input, callback),
      };

      this.collectionsSelect = new TomSelect(collectionsList, config);
    }
  }

  onCollectionChange(collection_id, path) {
    axios
      .post(path, { collection_id }, { headers: this.getHeader() })
      .catch((error) => {
        console.log({ error });
      });
  }

  onCollectionCreate(_input, _callback) {
    const data = { name: _input };
    axios
      .post(`/collections`, data, { headers: this.getHeader() })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        _callback({ value: data.id, text: data.name });
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  getHeader() {
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": this.csrfToken(),
    };

    return headers;
  }

  csrfToken() {
    const token = document.getElementsByName("csrf-token")[0].content;
    return token;
  }
}
