import { Controller } from "@hotwired/stimulus"
import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer"

// Connects to data-controller="maps"
export default class extends Controller {
  static values = { markers: String };

  connect() {
    if (typeof (google) != "undefined") {
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map();
    this.addMarkers();
    // use this to debug the map in the console 
    window.google_map = this.map();
  }

  map() {
    const HCM_COORDINATES = { lat: 10.762622, lng: 106.660172 };
    if (this._map == undefined) {
      this._map = new google.maps.Map(document.getElementById("map"), {
        center: new google.maps.LatLng(HCM_COORDINATES.lat, HCM_COORDINATES.lng),
        zoom: 16,
        // this mapId is used for styling the map
        mapId: "17c18af4519a565"
      });
    }
    return this._map
  }

  addMarkers() {
    const map = this.map();
    const markerValues = this.getMarkerValues();
    let markers = [];
    let bounds = new google.maps.LatLngBounds();

    // Create markers.
    for (let i = 0; i < markerValues.length; i++) {
      const position = new google.maps.LatLng(markerValues[i].lat, markerValues[i].lng);
      const icon = this.customizeIcon(markerValues[i].avatar_url);
      const marker = new google.maps.Marker({
        position: position,
        icon: icon,
        map: map,
        optimized: false
      });

      bounds.extend(position);
      markers.push(marker);
    };

    map.fitBounds(bounds);
    this.overlay();
    this.markerClusterer(markers);
  }

  customizeIcon(avatar_url) {
    const icon = {
      url: avatar_url,
      scaledSize: new google.maps.Size(32, 32), // scaled size
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 32),
      shape: { coords: [17, 17, 18], type: 'circle' },
      optimized: false
    }

    return icon;
  }

  markerClusterer(markers) {
     // Add a marker clusterer to manage the markers.
     const markerCluster = new MarkerClusterer({ map: this.map(), markers, algorithm: new GridAlgorithm({maxZoom: 6}) });
  }

  overlay() {
    const myoverlay = new google.maps.OverlayView();
    myoverlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer';
    };
    myoverlay.setMap(this.map());
  }

  getMarkerValues() {
    return JSON.parse(this.markersValue)
  }
}
