import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import axios from "axios";

// Connects to data-controller="skills"
export default class extends Controller {
  connect() {
    this.initTomSelect();
  }

  disconnect() {
    if (this.skillsSelect) {
      this.skillsSelect.destroy();
    }
  }

  // initTomSelect() {
  //   const skillsList = document.querySelector("#skills-list");
  //   const addSkillPath = "/user_skills";
  //   const removeSkillPath = "/user_skills/delete_skills";

  //   if (skillsList) {
  //     const config = {
  //       plugins: {
  //         remove_button: {
  //           title: "Remove this item",
  //         },
  //       },
  //       persist: true,
  //       onItemAdd: (value) => this.onSkillChange(value, addSkillPath),
  //       onItemRemove: (value) => this.onSkillChange(value, removeSkillPath),
  //       create: (input, callback) => this.onSkillCreate(input, callback),
  //     };

  //     this.skillsSelect = new TomSelect(skillsList, config);
  //   }
  // }

  initTomSelect() {
    const addSkillPath = "/user_skills";
    const removeSkillPath = "/user_skills/delete_skills";

    // Query all select elements that have IDs starting with "skills-list-"
    document.querySelectorAll("select[id^='skills-list-']").forEach((skillsList) => {
      if (skillsList && !skillsList.tomSelect) { // Ensure Tom Select isn't initialized already
        const config = {
          plugins: {
            remove_button: {
              title: "Remove this item",
            },
          },
          persist: true,
          onItemAdd: (value) => this.onSkillChange(value, addSkillPath),
          onItemRemove: (value) => this.onSkillChange(value, removeSkillPath),
          create: (input, callback) => this.onSkillCreate(input, callback),
        };

        new TomSelect(skillsList, config);
      }
    });
  }

  onSkillChange(skill_id, path) {
    axios
      .post(path, { skill_id }, { headers: this.getHeader() })
      .catch((error) => {
        console.log({ error });
      });
  }

  onSkillCreate(_input, _callback) {
    const data = { name: _input };
    axios
      .post(`/skills`, data, { headers: this.getHeader() })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        _callback({ value: data.id, text: data.name });
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  getHeader() {
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": this.csrfToken(),
    };

    return headers;
  }

  csrfToken() {
    const token = document.getElementsByName("csrf-token")[0].content;
    return token;
  }
}
