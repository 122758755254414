import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"
import axios from "axios"

export default class extends Controller {
  static targets = [ "field", "menuAnchor", "form" ];
  static values = { resource: String }

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'full_name',
      values: this.getUsers,
      noMatchTemplate: `<div class="hidden"></div>`,
      replaceTextSuffix: '',
      menuItemLimit: 8,
      containerClass: "tribute-container min-w-max shadow bg-white border border-gray-100 absolute z-50 my-2",
      itemClass: "border border-gray-100 px-3 py-1.5 cursor-pointer hover:bg-purple-200 text-gray-800 space-x-16",
      selectClass: "bg-purple-200",
      menuItemTemplate: function (item) {
        return item.original.menu_item;
      },
      selectTemplate: function (item) {
        return item.original.select_item;
      },
      menuContainer: this.menuAnchorTarget,
      positionMenu: true
    });
    this.tribute.attach(this.fieldTarget);

    // track all mentions before form submits
    this.formTarget.addEventListener("submit", this.submitWithMentions.bind(this));
    
    // Setup specifically for Trix 
    if (this.editor) {

      // Override the pasteHtml method of tribute because it's wonky/
      // The new method will delete the mention text entered by the user. 
      this.tribute.range.pasteHtml = this._pasteHtml.bind(this);

      // When tribute has replaced the text with the mention template, use a callback
      // to create an attachment for Trix. 
      this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
    }
  }

  getUsers(text, callback) {
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName("csrf-token")[0].content
    };
    axios.get(`/mentions`, { headers: headers, params: {query: text} })
      .then(users => { callback(users.data)})
      .catch(error => callback([]))
  }

  submitWithMentions(event) {
    event.preventDefault();

    // Track existing mentions in the field
    let mentions = Array.from(this.fieldTarget.querySelectorAll("div.tribute-mention"));

    // Update form input field for mentions.
    let mentionedIdsInputHTML = "";
    mentions.forEach((element) => {
      mentionedIdsInputHTML += `
        <input value=${element.id.replace("mentioned_user_", '')}
               type="hidden" multiple="multiple"
               name="${this.resourceValue}[mentioned_user_ids][]" 
               id="${this.resourceValue}_mentioned_user_ids" >
      `;
    })
    this.formTarget.insertAdjacentHTML('afterbegin', mentionedIdsInputHTML);

    this.formTarget.submit();
  }

  //////////////////////////////////////////////////////////
  ////              FOR TRIX EDITOR ONLY               /////
  //////////////////////////////////////////////////////////

  replaced(e) {
    let mention = e.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.select_item
    });
    this.editor.insertAttachment(attachment);
  }

  _pasteHtml(html, startPos, endPos) {
    let curPos = this.editor.getPosition();
    startPos = curPos - (endPos - startPos);
    this.editor.setSelectedRange([startPos, curPos]);
    this.editor.deleteInDirection("backward");
  }
}