// CSS
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "tom-select/dist/css/tom-select.css";
import "flatpickr/dist/flatpickr.css";
import "swiper/css/bundle";
import "@fortawesome/fontawesome-free/js/all";
import "../assets/stylesheets/actiontext";
import "../assets/stylesheets/bdr";
import "../assets/stylesheets/notifications";
import "../assets/stylesheets/ems";
import "../assets/stylesheets/simple_calendar";

// JS

import "@hotwired/turbo-rails";
require("@rails/ujs").start();

import "@rails/actiontext";
import "./controllers";
import "./helpers/turbo_frame_helper";

import TomSelect from "tom-select";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import axios from "axios";
window.axios = axios;

import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();
window.TomSelect = TomSelect;

// Google maps api callback
window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};

const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

// import "swiper/css/bundle";
