import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";
import { OrgChart } from "d3-org-chart";
import { searchInputListen, clearSearchInput, hideSearchResults, clearSearchResults } from "../helpers/org_charts_helper"

// Connects to data-controller="org-charts"
export default class extends Controller {
  static values = { row: String, isManager: Boolean };
  static targets = ["searchInput", "searchResults"];

  connect() {
    this.rawChart();
  }

  chart() {
    if (this._chart == undefined) {
      this._chart = new OrgChart();
    }
    return this._chart
  }

  onLocateNode(event) {
    const nodeId = event.currentTarget.dataset.nodeId;
    const chart = this.chart();

    if (nodeId) {
      chart.clearHighlighting();

      chart.setCentered(nodeId);
      chart.setHighlighted(nodeId);
      chart.render();

      clearSearchInput(this.searchInputTarget);
      hideSearchResults(this.searchResultsTarget);
      clearSearchResults(this.searchResultsTarget);
    }
  }


  rawChart() {
    const data = this.rowData();
    const isManager = this.isManagerValue;

    this.chart()
      .container('.chart-container')
      .data(data)
      .svgWidth(900)
      .nodeHeight((d) => {
        return 85;
      })
      .nodeWidth((d) => {
        return 210;
      })
      .childrenMargin((d) => {
        return 50;
      })
      .compactMarginBetween((d) => 50)
      .compactMarginPair((d) => 60)
      .neightbourMargin((a, b) => 25)
      .siblingsMargin((d) => 25)
      .buttonContent(({ node, state }) => {
        return `<div class="flex items-center bg-white m-auto p-1 border rounded-md text-xs"><span style="font-size:16px">${node.children
          ? `<svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>`
          : `<svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>`
          }</span></div>`;
      })
      .linkUpdate(function (d, i, arr) {
        d3.select(this)
          .attr('stroke', (d) => {
            return d.data._upToTheRootHighlighted ? '#152785' : '#E4E2E9'
          }
          )
          .attr('stroke-width', (d) =>
            d.data._upToTheRootHighlighted ? 2 : 1
          );

        if (d.data._upToTheRootHighlighted) {
          d3.select(this).raise();
        }
      })
      .nodeUpdate(function (d, i, arr) {

        d3.select(this)
          .select('.node-rect')
          .attr("stroke-width", d.data._highlighted || d.data._upToTheRootHighlighted ? 1 : 1)
      })
      .nodeContent(function (d, i, arr, state) {
        const color = '#FFFFFF';
        const borderColor = d.data._highlighted ? "border-green-600	" : "inherit";
        const data = d.data;
        if (data.type === "Project") {
          return `
            <div class="text-center	bg-white relative -mt-px -ml-px border ${borderColor} rounded-lg" style="width:${d.width}px;height:${d.height}px;">
              <div class="absolute flex justify-center items-center bg-none w-full h-12 -mt-6">
                <div class="bg-white border rounded-full w-0 h-0"></div>
              </div>
              <div class="w-full h-12 text-base text-center py-4" style="color:#08011E;">
                <span>${d.data.name}</span>
              </div>
              ${data.parentId &&
            `<div class="flex justify-between items-centter py-1 px-5">
                  <div>Members: ${data.noOfMembers} 👤</div>
                  <div>Teams: ${data.noOfTeams}</div>
                </div>`
            }

               ${data.id == "root" && data.size ?
              `<div class="flex flex-col justify-between items-centter py-1 px-5">
                  <div>Projects: ${data.size} </div>
                </div>` : ''
            }
            </div>`;
        } else if (data.type === "Team") {
          return `
          <div class="text-center	bg-white relative -mt-px -ml-px border ${borderColor} rounded-lg" style="width:${d.width}px;height:${d.height}px;">
              <div class="bg-none absolute flex justify-center items-center w-full h-12 -mt-6">
                <div class="bg-white border rounded-full w-0 h-0"></div>
              </div>
              <div class="w-full h-12 text-base text-center py-4">
                <span>${d.data.name}</span>
              </div>
              ${data.parentId &&
            `<div class="flex justify-start items-center py-1 px-5">
                  <div>Members: ${data.noOfMembers} 👤</div>
                </div>`
            }
            </div>`;
        } else if (data.type === "User") {
          return `
          <div class="relative text-center	bg-white relative z-0 -mt-px -ml-px border ${borderColor} rounded-lg" style="width:${d.width}px;height:${d.height}px;position:absolute;z-index:0">
              <div class="bg-none absolute flex justify-center items-center w-full h-12 -mt-7">
                <div class="bg-white rounded-full w-12 h-12">
                  ${data.avatarUrl ?
              `<img src=${data.avatarUrl} class="flex justify-center items-center border rounded-full h-full w-full object-cover"/>` :
              `<div class="flex justify-center items-center border rounded-full h-full w-full text-xl text-gray-400">${data.initialsCharacter}</div>`
            }
                </div>
                <div class="absolute z-10 right-3 bottom-0 text-sm"  x-data="{ open: false }" class="relative" @mouseover="open = true" @mouseleave="open = false">
                  ...
                  <div x-show="open" x-cloak class="flex flex-col justify-center items-start bg-white w-40 border rounded-lg p-2 shadow-xl absolute -left-12	-top-16 text-xs text-left">
                    <div>
                      <span class="font-semibold">Skills</span>: ${data.skills}
                    </div>
                    <div>
                      <span class="font-semibold">Start date</span>: ${data.startDate}
                    </div>
                    <div>
                      <span class="font-semibold">DRs</span>: ${data.noDRs}
                    </div>
                    ${data.activeProjects ?
              `<div>
                        <span class="font-semibold">Projects</span>: ${data.activeProjects} 
                      </div>
                      ` : ''
            }
                  </div>
                </div>
              </div>
              <div class="relative flex flex-col justify-center items-center w-full h-full pt-4 text-sm text-center">
                <div class="text-sm">${data.name}</div>
                <div class="text-xs text-gray-400">${data.position}</div>
                ${data.activeProjects ?
              `<div class="w-full px-2.5 overflow-hidden whitespace-nowrap text-ellipsis text-[10px] text-gray-400">
                    ${data.activeProjects} 
                  </div>
                  ` : ''
            }
                ${data.projectLead ?
              `<span class="text-xs font-light hidden">
                    Project Leader
                  </span>` : ``
            }
                ${data.teamLead ?
              `<span class="text-xs font-light hidden">
                    Team Leader
                  </span>` : ``
            }
              </div>
              ${isManager ?
              `
                    <div class="absolute right-0.5 bottom-0.5">
                      <a href=${data.userDataLink} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3818 12.7396L4.91213 15.0879L7.26048 14.6183L13.9393 7.93939C14.4581 7.42061 14.4581 6.5795 13.9393 6.06072C13.4206 5.54194 12.5794 5.54193 12.0607 6.06072L5.3818 12.7396ZM11 5.00006L4.2141 11.786C4.0745 11.9256 3.97934 12.1034 3.94062 12.297L3.29418 15.5292C3.15423 16.2289 3.77115 16.8458 4.47087 16.7059L7.7031 16.0594C7.8967 16.0207 8.0745 15.9256 8.2141 15.786L15 9.00006C16.1046 7.89549 16.1046 6.10463 15 5.00006C13.8954 3.89549 12.1046 3.89549 11 5.00006Z" fill="#B1B1B4"/> </svg>
                      </a>
                    </div>
                  ` : ''
            }
          </div>`;
        }

      })
      .render();

    searchInputListen(this.searchInputTarget, this.searchResultsTarget, data);

  }

  rowData() {
    return JSON.parse(this.rowValue)
  }
}
