import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [
    "leaveType",
    "startDate",
    "endDate",
    "endDateWrapper",
    "halfDayType",
    "totalDaysOff",
  ];

  connect() {
    this.initTomSelect();
    this.leaveTypeTarget.addEventListener(
      "change",
      this.handleLeaveTypeChange.bind(this)
    );
    if (this.getLeaveType()) {
      this.handleLeaveTypeChange();
    }
  }

  initTomSelect() {
    setTimeout(() => {
      document.querySelectorAll(".tom-select").forEach((el) => {
        new TomSelect(el, {
          plugins: ["clear_button"],
        });
      });
    }, 1500);
  }

  isHalfDayOff() {
    let leaveTypeName = this.getLeaveType();
    console.log("leave type name", leaveTypeName);
    return leaveTypeName.includes("HALF");
  }

  handleLeaveTypeChange() {
    let isHalfDayOff = this.isHalfDayOff();
    console.log("isHalfDayOff", isHalfDayOff);
    this.updateEndDateWrapperTarget();
    this.updateVisibleHalfDayTypeTarget();
    this.showTotalDaysOff();
    this.updateTotalDaysOff();
  }

  updateEndDateWrapperTarget() {
    let isHalfDayOff = this.isHalfDayOff();
    if (isHalfDayOff) {
      this.endDateWrapperTarget.setAttribute("disabled", true);
      this.endDateWrapperTarget.style.opacity = 0.5;
    } else {
      this.endDateWrapperTarget.removeAttribute("disabled");
      this.endDateWrapperTarget.style.opacity = 1;
    }

    this.endDateWrapperTarget.querySelectorAll("select").forEach((element) => {
      if (isHalfDayOff) {
        element.setAttribute("disabled", true);
      } else {
        element.removeAttribute("disabled");
      }
    });
  }

  updateVisibleHalfDayTypeTarget() {
    if (this.isHalfDayOff()) {
      this.halfDayTypeTarget.hidden = false;
      this.halfDayTypeTarget
        .querySelectorAll('input[type="radio"]')
        .forEach((element) => {
          element.removeAttribute("disabled");
        });
    } else {
      this.halfDayTypeTarget.hidden = true;
      this.halfDayTypeTarget
        .querySelectorAll('input[type="radio"]')
        .forEach((element) => {
          element.setAttribute("disabled", true);
        });
    }
  }

  showTotalDaysOff() {
    this.totalDaysOffTarget.hidden = false;
  }

  updateTotalDaysOff() {
    let totalDaysOff = this.calculateTotalDaysOff();
    let element = this.totalDaysOffTarget.querySelector("#working-days-count");
    console.log(
      "updating ",
      this.totalDaysOffTarget,
      "with ",
      totalDaysOff,
      "via ",
      element
    );

    if (element) {
      // Ensure element is found
      if (!totalDaysOff) {
        element.value = "-";
      } else {
        element.value = totalDaysOff;
      }
    } else {
      console.error("Element with id 'working-days-count' not found.");
    }
  }

  calculateTotalDaysOff() {
    let startDate = this.getStartDate();
    let endDate = this.getEndDate();

    if (this.isHalfDayOff()) {
      return 0.5;
    } else if (startDate <= endDate) {
      let datesInRange = this.getDatesInRange(startDate, endDate);

      return datesInRange.filter((date) => ![6, 0].includes(date.getDay()))
        .length;
    } else {
      return "";
    }
  }

  onStartDateChange() {
    console.log("start date changed");
    this.updateTotalDaysOff();
  }

  onEndDateChange() {
    console.log("end date changed");
    this.updateTotalDaysOff();
  }

  getStartDate() {
    return new Date(this.startDateTarget.value);
  }

  getEndDate() {
    return new Date(this.endDateTarget.value);
  }

  getLeaveType() {
    return this.leaveTypeTarget.options[this.leaveTypeTarget.selectedIndex]
      .text;
  }

  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
}
