import { Controller } from "@hotwired/stimulus"
import axios from "axios";

// Connects to data-controller="user-checklist-items"
export default class extends Controller {
  connect() {
    console.log(this.element)
  }

  onToggleItem(event) {
    const itemId = event.target.dataset.id;
    const userChecklistId = event.target.dataset.userChecklistId;
    const isChecked = event.target.checked;

    axios
      .post(`/user_checklists/${userChecklistId}/items/${itemId}/toggle`, { isChecked }, { headers: this.getHeader() })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log({ error });
      });
  }

  getHeader() {
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": this.csrfToken(),
    };

    return headers;
  }

  csrfToken() {
    const token = document.getElementsByName("csrf-token")[0].content;
    return token;
  }
}
