// Search
export function searchInputListen(searchInputElement, searchResultsElement, data) {
  searchInputElement.addEventListener('input', function () {
    var value = searchInputElement.value ? searchInputElement.value.trim() : '';
    if (value.length == 0) {
      clearSearchResults(searchResultsElement);
      hideSearchResults(searchResultsElement);
    } else {
      var regexSearchWord = new RegExp(value, "i");
      var searchResults = data.filter(node => {
        return node.name.match(regexSearchWord) || node?.skills?.match(regexSearchWord)
      });
      reflectResults(searchResultsElement, searchResults);
    }

  });
};

export function reflectResults(searchResultsElement, results) {
  var htmlStringArray = [];

  if (results.length != 0) {
    htmlStringArray = results.map(function (result) {
      var strVar = "";
      strVar += '<div class="flex border border-slate-50 rounded bg-gray-50 h-12 p-1 hover:bg-slate-200 hover:cursor-pointer" data-node-id="' + result.id + '" data-action="click->org-charts#onLocateNode" >'
      strVar += '<div class="flex justify-start items-center grow p-1 text-sm overflow-hidden">'
      strVar += '<span class="whitespace-nowrap overflow-hidden	text-ellipsis">' + result.name + '</span>'
      strVar += '</div>'
      strVar += '</div>'

      return strVar;
    })

    clearSearchResults(searchResultsElement);
    searchResultsElement.innerHTML = htmlStringArray.join('');
    showSearchResults(searchResultsElement);
  } else {
    hideSearchResults(searchResultsElement);
  }
};

export function clearSearchInput(searchInputElement) {
  searchInputElement.value = "";
}

export function hideSearchResults(searchResultsElement) {
  searchResultsElement.style.opacity = 0;
}

function showSearchResults(searchResultsElement) {
  searchResultsElement.style.opacity = 1;
}

export function clearSearchResults(searchResultsElement) {
  searchResultsElement.innerHTML = "";
}
// end search