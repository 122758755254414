window.clearTurboFrameContent = function (element) {
  let currentElement = element;
  while (currentElement) {
    if (currentElement.tagName === "TURBO-FRAME") {
      currentElement.innerHTML = "";
      break;
    }
    currentElement = currentElement.parentElement;
  }
};
