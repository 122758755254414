import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [ "yearSelector", "requestList" ];
  static values = { year: Number };

  connect() {
    this.yearSelectorTarget.addEventListener("change", this.getRequestList.bind(this));
    this.headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName("csrf-token")[0].content
    };
  }

  getRequestList(event) {
    let selectedYear = event.target.value;
    axios.get(`/leave_requests/list`, { headers: this.headers, params: { year: selectedYear } })
      .then(res => { 
        this.newListHTML = res.data;
        this.requestListTarget.style.opacity = 0;
        setTimeout(this.updateRequestListHTML.bind(this), 200);
      })
      .catch(() => {})
  }

  updateRequestListHTML() {
    this.requestListTarget.innerHTML = this.newListHTML;
    this.requestListTarget.style.opacity = 1;
  }
}