import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [ "notiCheckbox", "selectAll", "markAsRead", "markAsUnread" ];

  connect() {
    this.headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName("csrf-token")[0].content
    };

    // notifications contains the li elements that will be used for updating styles,
    // notificationIds contains the ids to send to the server for processing
    this.chosenNotifications = new Set();
    this.chosenNotificationIds = new Set();
    
    this.markAsReadTarget.addEventListener("click", this.markAsRead.bind(this));
    this.markAsUnreadTarget.addEventListener("click", this.markAsUnread.bind(this));

    this.selectAllTarget.addEventListener("click", this.updateAllNotifications.bind(this));
    this.notiCheckboxTargets.forEach((target) => {
      target.addEventListener("click", this.updateChosenNotifications.bind(this));
    });

    // The count bubble that appears at the top right of the notification bell
    this.unreadCountIndicator = document.querySelector("#notification-count");
    this.unreadCount = Number(this.unreadCountIndicator?.innerHTML) || 0;
  }

  updateAllNotifications(event) {
    if (event.target.checked) {
      this.notiCheckboxTargets.forEach((checkbox) => {
        if (!checkbox.checked) checkbox.click();
      });
    } else {
      this.notiCheckboxTargets.forEach((checkbox) => {
        if (checkbox.checked) checkbox.click();
      });
    }
  }

  updateChosenNotifications(event) {
    if (event.target.checked) {
      this.chosenNotificationIds.add(Number(event.target.value));
      this.chosenNotifications.add(document.querySelector(`#notification_${event.target.value}`));
    } else {
      this.chosenNotificationIds.delete(Number(event.target.value));
      this.chosenNotifications.delete(document.querySelector(`#notification_${event.target.value}`));
    }
  }

  markAsRead(event) {
    event.preventDefault();
    let data = { notification: { ids: Array.from(this.chosenNotificationIds) } };
    axios.post(`/notifications/read`, data, { headers: this.headers })
      .then((res) => {
        this.styleChosenNotifications("read");
        this.updateNotificationCount(this.unreadCount - res.data.count);
      })
      .catch(() => {})
  }

  markAsUnread(event) {
    event.preventDefault();
    let data = { notification: { ids: Array.from(this.chosenNotificationIds) } };
    axios.post(`/notifications/unread`, data, { headers: this.headers })
      .then((res) => {
        this.styleChosenNotifications("unread");
        this.updateNotificationCount(this.unreadCount + res.data.count);
      })
      .catch(() => {})
  }

  styleChosenNotifications(state) {
    this.chosenNotifications.forEach((element) => {
      let indicator = element.querySelector("span.notification-indicator")
      
      if (state === "read") {
        element.className = element.className.replace('bg-gray-50', 'bg-gray-200 bg-opacity-80');
        indicator.className = indicator.className.replace('bg-blue-400', 'bg-gray-200 bg-opacity-80');

      } else {
        element.className = element.className.replace('bg-gray-200 bg-opacity-80', 'bg-gray-50');
        indicator.className = indicator.className.replace('bg-gray-200 bg-opacity-80', 'bg-blue-400');
      }
    });
  }

  updateNotificationCount(newCount) {
    this.unreadCount = newCount;
    this.unreadCountIndicator.innerHTML = newCount;
    if (newCount > 0) {
      this.unreadCountIndicator.className = this.unreadCountIndicator.className.replaceAll("hidden", "");
    } else {
      this.unreadCountIndicator.className += " hidden";
    }
  }
}