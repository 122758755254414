import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field" ];
  static values = { placeholder: String };

  connect() {
    // configure placeholder
    this.fieldTarget.innerHTML = this.placeholderValue;
    this.fieldTarget.classList.add('text-gray-500');
    this.fieldTarget.addEventListener('focus', this.placeholderOnFocus.bind(this));
    this.fieldTarget.addEventListener('blur', this.placeholderOnBlur.bind(this));

    // configure paste events so that all styling will got stripped off. We want only
    // the tags (layout) + text data
    this.fieldTarget.addEventListener('paste', this.stripStylingOnPaste.bind(this))
  }

  placeholderOnFocus() {
    if (this.fieldTarget.innerHTML === this.placeholderValue) {
      this.fieldTarget.innerHTML = '';
      this.fieldTarget.classList.remove('text-gray-500');
    };
  }

  placeholderOnBlur() {
    if (this.fieldTarget.innerHTML === '') {
      this.fieldTarget.innerHTML = this.placeholderValue;
      this.fieldTarget.classList.add('text-gray-500');
    };
  }

  stripStylingOnPaste(event) {
    event.preventDefault();
    let styleRegEx =  /style="[^\"]*"/gi
    let htmlData = event.clipboardData.getData("text/html");
    const strippedHTML = htmlData.replace(styleRegEx, ``);
    document.execCommand("insertHTML", false, strippedHTML);
  }
}