import { Controller } from "@hotwired/stimulus"

/* THIS CONTROLLER ALLOWS TRIX EDITORS TO AUTO-HYPERLINK PASTED URLS */

export default class extends Controller {
  static targets = [ "trixContainer" ];

  connect() {
    this.trixContainerTarget.addEventListener("paste", this.pasteHandler.bind(this));
    this.editor = this.trixContainerTarget.editor;
    this.urlPattern = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/ig;
  }

  disconnect() {
    this.trixContainerTarget.removeEventListener("paste", this.pasteHandler.bind(this))
  }

  pasteHandler(event) {
    const pastedUrl = event.clipboardData?.getData?.("Text")
    if (!!pastedUrl && !!pastedUrl.match(this.urlPattern)) {
      this.hyperlinkUrl(pastedUrl)
    }
  }

  hyperlinkUrl(pastedUrl) {
    let currentText = this.editor.getDocument().toString()
    let cursorPosition = this.editor.getSelectedRange()
    let startOfPastedUrl = currentText.lastIndexOf(pastedUrl)

    // Select the url and hyperlink it
    this.editor.setSelectedRange([startOfPastedUrl, cursorPosition[0]])
    this.editor.activateAttribute('href', pastedUrl)

    // Return to current cursor position
    this.editor.setSelectedRange(cursorPosition)
  }
}